<template>
  <div>
    <div style="margin-top: 15px" v-for="(product, i) in services" :key="i">
      <div>
        <Row>
          <i-col span="4">
            {{product.serviceItemName }}
          </i-col>
          <i-col span="4">{{ product.typeName }}</i-col>
          <i-col span="4">
            {{ product.serviceQuantity }} {{ product.serviceQuantityName }}
          </i-col>
          <i-col span="4">
            {{ product.signServiceQuantity }}{{
              product.serviceQuantityName
            }}
          </i-col>
          <i-col span="4">
             {{formatMoney(product.unitPrice) }}/{{
              product.serviceQuantityName
            }}
            <!-- <InputNumber
              v-model="product.signNormalPrice"
              @on-blur="invalidSellNumber(product)"
              :min="0"
              size="small"
            ></InputNumber> -->
          </i-col>
          <i-col span="4">
            <InputNumber
              v-model="product.price"
              :min="0"
              size="small"
            ></InputNumber>
            {{contractServiceFeeWay===1?'':('/'+product.serviceQuantityName)}}
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      contractServiceFeeWay: this.$store.getters.token.publisherSetting.contractServiceFeeWay // 服务费录入方式
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },

    getSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    refreshPrice (product) {
      product.signNormalPrice = product.normalSettlementPrice
    },
    changeSellSales (val, produce) {
      produce.signNormalPrice = Number(
        ((produce.normalUsePrice * val) / 10).toFixed(2)
      )
    },
    computedSale (usePrice, sellPrice) {
      if (usePrice > 0) {
        const discount = (sellPrice / usePrice) * 10
        const showDisCount = parseFloat(discount.toFixed(2))
        return showDisCount
      } else {
        return ''
      }
    },
    // 服务费采购
    EditServicePrice (product) {
      this.$emit('on-serviceSetting', { product: product })
    }
  },
  watch: {
    services: {
      // 监控档期费用录入后， 产生的变化。 直接提交到父窗体
      deep: true,
      handler: function () {
        this.$emit('on-serviceItemChange', { services: this.services })
      }
    }
  },
  created () {

  }
}
</script>

<style>
.serviceExample {
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: absolute;
  background-color: papayawhip;
  z-index: 999;
  width: 130px;
}
</style>
